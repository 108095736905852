import foodImg from "../components/Ministries/foodImg.jpg";
import choirImg from "../components/Ministries/choirImg.jpg";
import womenImg from "../components/Ministries/womenImgNEW.jpeg";
import bibleImg from "../components/Ministries/bibleImg.jpg";
import youthImg from "../components/Ministries/youthImgNEW.png";
import gallImg1 from "../utils/images/img1.jpg";
import gallImg2 from "../utils/images/img2.jpg";
import gallImg3 from "../utils/images/img3.jpg";
import gallImg4 from "../utils/images/img4.jpg";
import gallImg5 from "../utils/images/img5.jpg";
import gallImg6 from "../utils/images/img6.jpg";
import gallImg7 from "../utils/images/img7.jpg";
import gallImg8 from "../utils/images/img8.jpg";
import gallImg9 from "../utils/images/img9.jpg";
import gallImg10 from "../utils/images/img10.jpg";
import gallImg11 from "../utils/images/img11.jpg";
import gallImg12 from "../utils/images/img12.jpg";
import gallFlyer1 from "../utils/images/flyer1.jpeg";
import gallFlyer2 from "../utils/images/flyer2.jpeg";
import gallFlyer3 from "../utils/images/flyer3.jpeg";
import gallFlyer4 from "../utils/images/flyer4.jpeg";
import gallFlyer5 from "../utils/images/flyer5.jpeg";
import gallFlyer6 from "../utils/images/flyer6.jpeg";
import gallFlyer7 from "../utils/images/flyer7.jpeg";
import gallVal1 from "../utils/images/val1.jpeg";
import gallVal2 from "../utils/images/val2.jpeg";
import gallVal3 from "../utils/images/val3.jpeg";
import gallVal4 from "../utils/images/val4.jpeg";
import gallVal5 from "../utils/images/val5.jpeg";
import gallVal6 from "../utils/images/val6.jpeg";
import gallVal7 from "../utils/images/val7.jpeg";
import gallVal8 from "../utils/images/val8.jpeg";
import gallVal9 from "../utils/images/val9.jpeg";
import gallVal10 from "../utils/images/val10.jpeg";
import gallVal11 from "../utils/images/val11.jpeg";
import gallVal12 from "../utils/images/val12.jpeg";
import gallBackToSchool1 from "../utils/images/gallBackToSchool1.jpeg";
import gallBackToSchool2 from "../utils/images/gallBackToSchool2.jpeg";
import gallBackToSchool3 from "../utils/images/gallBackToSchool3.jpeg";
import gallBackToSchool4 from "../utils/images/gallBackToSchool4.jpeg";
import gallBackToSchool5 from "../utils/images/gallBackToSchool5.jpeg";
import gallBackToSchool6 from "../utils/images/gallBackToSchool6.jpeg";
import gallBackToSchool7 from "../utils/images/gallBackToSchool7.jpeg";
import gallBackToSchool8 from "../utils/images/gallBackToSchool8.jpeg";
import gallBackToSchool9 from "../utils/images/gallBackToSchool9.jpeg";
import gallBackToSchool10 from "../utils/images/gallBackToSchool10.jpeg";
import gallBackToSchool11 from "../utils/images/gallBackToSchool11.jpeg";
import gallBackToSchool12 from "../utils/images/gallBackToSchool12.jpeg";
import gallBackToSchool13 from "../utils/images/gallBackToSchool13.jpeg";
import gallBackToSchool14 from "../utils/images/gallBackToSchool14.jpeg";
import gallBackToSchool15 from "../utils/images/gallBackToSchool15.jpeg";

export const ministryInfo = [
  {
    img: bibleImg,
    href: "/biblestudy",
    title: "Bible Study",
    desc: "Guiding people to experience a relationship with God through which they come to know and do His will",
    descDetailed:
      "Bible Study helps to maintain our connection with God through his written Word. It reminds us of his character, promises, our dependence on him, and our future together. It familiarizes us with, and helps us grasp, the whole story of Scripture, and it keeps our souls energized.",
    day: "Wednesdays",
    time: "7:00 - 8:00 PM",
    startTime: "19:00",
    endTime: "20:00",
  },
  // {
  //   img: menImg,
  //   href: "/mensministry",
  //   title: "Men's Ministry",
  //   desc: "Spreading and celebrating the word of God among brothers through fellowship",
  //   descDetailed:
  //     "Men's Ministry brings men closer together in fellowship, growing, strengthening, and encouraging them to be the spiritual leaders the Lord called them to be. It is a place where men come together and grow in their spiritual journey.",
  //   day: "Thursdays",
  //   time: "6:00 - 8:00 PM",
  //   startTime: "18:00",
  //   endTime: "20:00",
  // },
  // {
  //   img: menImg,
  //   href: "/mensministry",
  //   title: "Men's Ministry",
  //   desc: "Spreading and celebrating the word of God among brothers through fellowship",
  //   descDetailed:
  //     "Men's Ministry brings men closer together in fellowship, growing, strengthening, and encouraging them to be the spiritual leaders the Lord called them to be. It is a place where men come together and grow in their spiritual journey.",
  //   day: "Thursdays",
  //   time: "6:00 - 8:00 PM",
  //   startTime: "18:00",
  //   endTime: "20:00",
  // },
  {
    img: womenImg,
    href: "/womensministry",
    title: "Women's Ministry",
    desc: "Welcoming and connecting with other women into the life and works of the Gospel",
    descDetailed:
      "Women's Ministry seeks to support, build up, and empower women through prayer, Bible study, and fellowship. We create a loving and caring environment for all women, especially you, allowing you to thrive for the Lord.",
    day: "First Mondays",
    time: "6:00 - 8:00 PM",
    startTime: "18:00",
    endTime: "20:00",
  },
  {
    img: youthImg,
    href: "/youthministry",
    title: "Youth Ministry",
    desc: "High energy worship, relatable messages, and an atmosphere where youth are encouraged to freely express who they are in Christ Jesus!",
    descDetailed: "IN OUR YOUTH MINISTRY, YOU WILL:",
    ul: [
      "Be equipped and engaged in the Word of God",
      "Serve others",
      "Lead and affect others in their sphere of influence to know God and unashamedly profess Christ!",
    ],
    day: "Third Sundays",
    time: "10:00 - 11:30 PM",
    startTime: "10:00",
    endTime: "11:30",
  },
  {
    img: choirImg,
    href: "/choirministry",
    title: "Worship Ministry",
    desc: "Leading God's people to adore, praise, give thanks, express emotion, and repent during worship",
    descDetailed:
      "The Praise and Worship Ministry guides and leads of believers through music towards God in such a way that both celebrates and glorifies Him, as well as encourages and admonishes those who follow Him. The Praise and Worship Ministry is also an avenue from which the church can express their hearts of gratitude toward God, through song, and which allows the believer to share their otherwise inexpressible and glorious joy",
    day: "Sundays",
    time: "9:30 - 10:15 AM",
    startTime: "09:30",
    endTime: "10:15",
  },
  {
    img: foodImg,
    href: "/foodministry",
    title: "Food Ministry",
    desc: "Our Food Pantry Ministry is a community outreach that recognizes that God has blessed us to be a blessing and serves as a vehicle to share the overwhelming abundance God has showered on us with those in need in our community.",
    descDetailed:
      "Our food pantry is open every Tuesday afternoon from 3:30 – 5:30 PM. We provide food in the community with love for those we serve. It is our intention that people in need receive not only food for their physical hunger, but generous portions of kindness and grace to feed their spiritual and emotional hunger. We believe the words of Jesus that this is one way we can respond directly to God's love for us:",

    scripture:
      "'For I was hungry and you gave me something to eat, I was thirsty and you gave me something to drink, I was a stranger and you invited me in.' -Matthew 25:35",
    day: "Tuesdays",
    time: "3:30 PM - 5:30 PM",
    startTime: "15:30",
    endTime: "17:30",
  },
];

export const galleryImages = [
  {
    tag: "MLK Parade",
    width: 2048,
    height: 1780,
    src: gallImg1,
  },
  {
    tag: "MLK Parade",
    width: 2048,
    height: 1807,
    src: gallImg2,
  },
  {
    tag: "MLK Parade",
    width: 2048,
    height: 1807,
    src: gallImg3,
  },
  {
    tag: "MLK Parade",
    width: 2048,
    height: 1262,
    src: gallImg4,
  },
  {
    tag: "MLK Parade",
    width: 2048,
    height: 1367,
    src: gallImg5,
  },
  {
    tag: "MLK Parade",
    width: 2048,
    height: 1367,
    src: gallImg6,
  },
  {
    tag: "MLK Parade",
    width: 2048,
    height: 1367,
    src: gallImg7,
  },
  {
    tag: "MLK Parade",
    width: 2048,
    height: 1367,
    src: gallImg8,
  },
  {
    tag: "MLK Parade",
    width: 2048,
    height: 1367,
    src: gallImg9,
  },
  {
    tag: "MLK Parade",
    width: 2048,
    height: 1367,
    src: gallImg10,
  },
  {
    tag: "MLK Parade",
    width: 2048,
    height: 1367,
    src: gallImg11,
  },
  {
    tag: "MLK Parade",
    width: 2048,
    height: 1367,
    src: gallImg12,
  },
  {
    tag: "Event Flyers",
    width: 721,
    height: 682,
    src: gallFlyer1,
  },
  {
    tag: "Event Flyers",
    width: 492,
    height: 658,
    src: gallFlyer2,
  },
  {
    tag: "Event Flyers",
    width: 625,
    height: 507,
    src: gallFlyer3,
  },
  {
    tag: "Event Flyers",
    width: 430,
    height: 658,
    src: gallFlyer4,
  },
  {
    tag: "Event Flyers",
    width: 464,
    height: 604,
    src: gallFlyer5,
  },
  {
    tag: "Event Flyers",
    width: 468,
    height: 594,
    src: gallFlyer6,
  },
  {
    tag: "Event Flyers",
    width: 468,
    height: 594,
    src: gallFlyer7,
  },
  {
    tag: "Valentine's Dinner",
    width: 720,
    height: 960,
    src: gallVal1,
  },
  {
    tag: "Valentine's Dinner",
    width: 720,
    height: 960,
    src: gallVal2,
  },
  {
    tag: "Valentine's Dinner",
    width: 720,
    height: 960,
    src: gallVal3,
  },
  {
    tag: "Valentine's Dinner",
    width: 720,
    height: 960,
    src: gallVal4,
  },
  {
    tag: "Valentine's Dinner",
    width: 720,
    height: 960,
    src: gallVal5,
  },
  {
    tag: "Valentine's Dinner",
    width: 720,
    height: 960,
    src: gallVal6,
  },
  {
    tag: "Valentine's Dinner",
    width: 720,
    height: 960,
    src: gallVal7,
  },
  {
    tag: "Valentine's Dinner",
    width: 720,
    height: 960,
    src: gallVal8,
  },
  {
    tag: "Valentine's Dinner",
    width: 720,
    height: 960,
    src: gallVal9,
  },
  {
    tag: "Valentine's Dinner",
    width: 720,
    height: 960,
    src: gallVal10,
  },

  {
    tag: "Valentine's Dinner",
    width: 720,
    height: 960,
    src: gallVal11,
  },
  {
    tag: "Valentine's Dinner",
    width: 720,
    height: 960,
    src: gallVal12,
  },
  {
    tag: "Back To School 2024",
    width: 2048,
    height: 1500,
    src: gallBackToSchool1,
  },
  {
    tag: "Back To School 2024",
    width: 720,
    height: 960,
    src: gallBackToSchool2,
  },
  {
    tag: "Back To School 2024",
    width: 720,
    height: 960,
    src: gallBackToSchool3,
  },
  {
    tag: "Back To School 2024",
    width: 720,
    height: 960,
    src: gallBackToSchool4,
  },
  {
    tag: "Back To School 2024",
    width: 720,
    height: 960,
    src: gallBackToSchool5,
  },
  {
    tag: "Back To School 2024",
    width: 2048,
    height: 1500,
    src: gallBackToSchool6,
  },
  {
    tag: "Back To School 2024",
    width: 720,
    height: 960,
    src: gallBackToSchool7,
  },
  {
    tag: "Back To School 2024",
    width: 720,
    height: 960,
    src: gallBackToSchool8,
  },
  {
    tag: "Back To School 2024",
    width: 2048,
    height: 1500,
    src: gallBackToSchool9,
  },
  {
    tag: "Back To School 2024",
    width: 2048,
    height: 1500,
    src: gallBackToSchool10,
  },
  {
    tag: "Back To School 2024",
    width: 720,
    height: 960,
    src: gallBackToSchool11,
  },
  {
    tag: "Back To School 2024",
    width: 720,
    height: 960,
    src: gallBackToSchool12,
  },
  {
    tag: "Back To School 2024",
    width: 720,
    height: 960,
    src: gallBackToSchool13,
  },
  {
    tag: "Back To School 2024",
    width: 720,
    height: 960,
    src: gallBackToSchool14,
  },
  {
    tag: "Back To School 2024",
    width: 720,
    height: 960,
    src: gallBackToSchool15,
  },
];
