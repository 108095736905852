import React from "react";
import { motion } from "framer-motion";
import { fadeIn, slideIn, staggerContainer } from "../../utils/motion";

import css from "./About.module.scss";

const About = () => {
  return (
    <motion.div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: false, amount: 0.05 }}
      className={css.wrapper}
    >
      <div className={css.container}>
        <div className={css.service}>
          {/* <div className={css.serviceImg}></div> */}
          <motion.div
            variants={fadeIn("up", "tween", 0.2, 0.5)}
            className={css.serviceInfo}
          >
            <div className={css.serviceTitle}>Join Us for Worship Service </div>
            <div className={css.serviceTimes}>Every Sunday at 10:30 a.m.</div>
          </motion.div>
        </div>
        <motion.div
          variants={fadeIn("up", "tween", 0.4, 0.5)}
          className={css.churchStatements}
        >
          <div className={css.purpose}>
            <section className={css.purposeTitle}> VISION STATEMENT</section>
            <section className={css.purposeInfo}>
              We will be a spiritually rich church of multiple ethnicities,
              where the vast majority of the membership is dedicated to speaking
              and living the truth of God's Holy Word, even at the risk of
              offending people on behalf of Christ. We will tangibly impact
              lives by demonstrating Jesus' love to people regardless of who
              they are or where they are. We will be a beacon of liberation in
              southwest Florida because we are the church known to speak truth
              to power.
            </section>
          </div>
          {/* <div className={css.mission}>
            <section className={css.missionTitle}> MISSION STATEMENT</section>
            <section className={css.missionInfo}>
              To spread the gospel of our Lord and Savior Jesus Christ
              throughout the world, winning souls for Christ, and establishing
              disciples for the Kingdom of God.
            </section>
          </div> */}
        </motion.div>
        <motion.div
          variants={slideIn("up", "tween", 0.7, 0.5)}
          className={css.resources}
        >
          Looking to grow your knowledge and love for God?
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.bible.com/bible/59/GEN.1.ESV"
          >
            <button>Online Bible</button>
          </a>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default About;
