import React, { useState } from "react";
import { Link } from "react-router-dom";
import churchLogo from "./ufmbcLogo2.png";
import "./Navbar2.css";

function Navbar2() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <div className="navbar-window">
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo">
            <img className="navbar-logo-img" src={churchLogo} alt="" />
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/ministries"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Ministries
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/gallery"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Gallery
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/contact"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Contact Us
              </Link>
            </li>
            <li className="nav-item">
              <a
                id="give"
                className="nav-links"
                onClick={closeMobileMenu}
                rel="noreferrer"
                href="https://onrealm.org/UnityFaithMissi/-/form/give/now"
                target="_blank"
              >
                Give
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Navbar2;
